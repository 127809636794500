import { CONFIG } from 'src/config-global';
import axiosInstance from 'src/utils/axios';
import { create } from 'zustand';

// Timer Constants (in milliseconds)
const PING_INTERVAL = 10000; // 10 seconds
const RECONNECTION_DELAY = 3000; // 3 seconds
const INACTIVITY_TIMEOUT = 60000; // 1 minute
const TAB_INACTIVE_TIMEOUT = 20000; // 20 seconds

const createChatStore = (set, get) => ({
  socket: null,
  isConnected: false,
  messages: [],
  isLoading: false,
  isExpanded: false,
  isChatActive: true,
  selectedOption: null,
  suggestedQuestions: [],
  pingInterval: null,
  sources: [],
  relatedQuestions: [],
  tags: [],
  socketRef: null,
  pingIntervalRef: null,
  input: '',

  setLoading: (isLoading) => set({ isLoading }),

  // Actions
  setIsExpanded: (value) => set({ isExpanded: value }),
  setIsChatActive: (value) => set({ isChatActive: value }),
  setSelectedOption: (option) => set({ selectedOption: option }),

  // Add new action to fetch suggested questions
  fetchSuggestedQuestions: async (id) => {
    try {
      const { data } = await axiosInstance.get(`/chat/questions${id ? `?alert_id=${id}` : ''}`);
      set({ suggestedQuestions: data });
    } catch (error) {
      console.log('Failed to fetch suggested questions:', error);
      set({ suggestedQuestions: [] });
    }
  },

  setInput: (value) => set({ input: value }),

  resetChat: () => {
    const { socketRef, pingIntervalRef } = get();
    set({ messages: [], input: '' });

    if (socketRef) {
      socketRef.close();
      set({ socketRef: null });
    }

    set({ isConnected: false });

    if (pingIntervalRef) {
      clearInterval(pingIntervalRef);
      set({ pingIntervalRef: null });
    }
  },

  resetPing: () => {
    const { socketRef, pingIntervalRef } = get();
    if (pingIntervalRef) {
      clearInterval(pingIntervalRef);
    }

    const newPingInterval = setInterval(() => {
      if (socketRef?.readyState === WebSocket.OPEN) {
        socketRef.send('ping');
      }
    }, PING_INTERVAL);

    set({ pingIntervalRef: newPingInterval });
  },

  initializeWebSocket: (user, id) => {
    if (!user || !user.accessToken) return;

    const socket = new WebSocket(
      `${CONFIG.site.webSocketURL}/chat?token=${encodeURIComponent(user.accessToken)}${id ? `&alert_id=${id}` : ''}`
    );

    socket.onopen = () => {
      set({ isConnected: true });
      get().resetPing();
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        // Handle token expiration
        if (data.type === 'error' && data.message?.toLowerCase().includes('token expired')) {
          get().resetChat();
          return;
        }

        // Rest of your existing onmessage logic
        if (data.type === 'pong') return;

        set((state) => {
          const currentMessages = state.messages;
          const currentLastMessage = currentMessages[currentMessages.length - 1];

          if (data.type === 'start') {
            set({ isLoading: true });
          }
          if (data.type === 'end' || data.type === 'tag') {
            set({ isLoading: false });
          }

          if (currentLastMessage && currentLastMessage.sender === 'bot' && data.sender === 'bot') {
            const updatedMessages = [...currentMessages];
            updatedMessages[updatedMessages.length - 1] = {
              ...currentLastMessage,
              message:
                data.type === 'stream'
                  ? currentLastMessage.message + (data.message || '')
                  : currentLastMessage.message + '',
              citations: data.message?.includes('#### Citations:')
                ? data.message.split('#### Citations:')[1]
                : currentLastMessage.citations,
              sources:
                data.type === 'sources' && data.sub_type === 'ctx_docs'
                  ? data.message
                  : currentLastMessage.sources,
              relatedQuestions:
                data.type === 'related'
                  ? [
                      ...(currentLastMessage.relatedQuestions || []),
                      ...(Array.isArray(data.message) ? data.message : [data.message]),
                    ]
                  : currentLastMessage.relatedQuestions || [],
              tags:
                data.type === 'tag'
                  ? [
                      ...(currentLastMessage.tags || []),
                      ...(Array.isArray(data.message) ? data.message : [data.message]),
                    ]
                  : currentLastMessage.tags || [],
            };
            return { messages: updatedMessages };
          }

          return {
            messages: [
              ...currentMessages,
              {
                ...data,
                citations: data.message?.includes('#### Citations:')
                  ? data.message.split('#### Citations:')[1]
                  : null,
                sources:
                  data.type === 'sources' && data.sub_type === 'ctx_docs' ? data.message : null,
                relatedQuestions:
                  data.type === 'related'
                    ? Array.isArray(data.message)
                      ? data.message
                      : [data.message]
                    : [],
                tags:
                  data.type === 'tag'
                    ? Array.isArray(data.message)
                      ? data.message
                      : [data.message]
                    : [],
              },
            ],
          };
        });
      } catch (error) {
        console.error('Error processing websocket message:', error);
      }
    };

    socket.onclose = () => {
      set({ isConnected: false });
      // Only attempt reconnection if the chat hasn't been reset
      const state = get();
      if (state.messages.length > 0) {
        setTimeout(() => get().initializeWebSocket(user, id), RECONNECTION_DELAY);
      }
    };

    set({ socketRef: socket });
  },

  sendMessage: (message) => {
    const { socketRef, resetPing } = get();
    if (!message.trim() || !socketRef) return;

    resetPing();
    socketRef.send(message);
    set({ input: '' });
  },

  handleRelatedQuestionClick: (question, user) => {
    const { socketRef, initializeWebSocket } = get();

    if (socketRef?.readyState === WebSocket.OPEN) {
      socketRef.send(question);
    } else {
      // Initialize new socket connection with the provided user
      initializeWebSocket(user);

      // Wait for connection to establish before sending message
      const checkAndSendMessage = setInterval(() => {
        const { socketRef: updatedSocketRef } = get();
        if (updatedSocketRef?.readyState === WebSocket.OPEN) {
          updatedSocketRef.send(question);
          clearInterval(checkAndSendMessage);
        }
      }, 100);

      // Clear interval after 5 seconds if connection fails
      setTimeout(() => clearInterval(checkAndSendMessage), 5000);
    }
  },
});

// Export constants for use in other files
export const CHAT_TIMEOUTS = {
  PING_INTERVAL,
  RECONNECTION_DELAY,
  INACTIVITY_TIMEOUT,
  TAB_INACTIVE_TIMEOUT,
};

export const useChatStore = create((set, get) => createChatStore(set, get));
